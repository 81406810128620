<template>
  <div class="main">
    <el-card>
      <el-form
        :inline="true"
        class="demo-form-inline"
      >
        <el-form-item label="设备名(房间号) : ">
          <el-input placeholder="设备名(房间号) : " />
        </el-form-item>
        <el-form-item label="设备名 : ">
          <el-input
            size="small"
            placeholder="设备码 : "
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="danger"
            icon="el-icon-search"
            round
          >
            全局搜索
          </el-button>
          <el-button
            type="danger"
            icon="el-icon-refresh"
            round
          >
            重置
          </el-button>
        </el-form-item>
      </el-form>
      <div class="button-group">
        <div>
          <el-button
            type="danger"
            icon="el-icon-plus"
            plain
          >
            添加
          </el-button>
          <el-button
            type="danger"
            icon="el-icon-edit"
            plain
          >
            修改
          </el-button>
          <el-button
            type="danger"
            icon="el-icon-refresh"
            plain
          >
            刷新
          </el-button>
          <el-button
            type="danger"
            icon="el-icon-edit"
            plain
          >
            批量操作
          </el-button>
        </div>
        <el-card style="margin:0 30px;flex: 1;">
          <div class="set-button">
            <div class="set-left">
              <div>
                <el-button
                  type="danger"
                  icon="Edit"
                  plain
                >
                  全部房间
                </el-button>
                <el-button
                  type="danger"
                  icon="Edit"
                  plain
                >
                  未分楼层的房间
                </el-button>
              </div>
              <div style="margin:20px 0">
                <el-button
                  type="danger"
                  icon="Edit"
                  plain
                >
                  管理楼栋
                </el-button>
                <el-button
                  type="danger"
                  icon="Edit"
                  plain
                >
                  管理楼层
                </el-button>
              </div>
              <div>
                <el-button
                  type="danger"
                  icon="Edit"
                  round
                >
                  分配到 ...
                </el-button>
              </div>
            </div>
            <div class="set-right">
              <el-form>
                <el-form-item style="background: #f5f5f5;">
                  楼栋 ：
                  <el-button
                    type="danger"
                    icon="Edit"
                    plain
                  >
                    我的楼栋
                  </el-button>
                </el-form-item>
                <el-form-item>
                  楼层 ：
                  <el-button
                    type="danger"
                    icon="Edit"
                    plain
                  >
                    我的楼栋
                  </el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </el-card>
      </div>
      <el-table
        style="width:100%;margin-top:30px;"
        border
        :data="rolesList"
      >
        <el-table-column
          align="center"
          label="设备码"
        >
          <template #default="{row}">
            {{ row.eid }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="设备名(房间号)"
        >
          <template #default="{row}">
            {{ row.housenumber }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="在线状态 | 门状态 | 信号"
        >
          <template #default="{row}">
            {{ row.state }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="电池电量(%)"
        >
          <template #default="{row}">
            {{ row.electricity }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="安装地址"
        >
          <template #default="{row}">
            {{ row.address }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="安装时间"
        >
          <template #default="{row}">
            {{ row.time }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="网关"
        >
          <template #default="{row}">
            {{ row.gateway }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          width="300px"
          label="操作"
        >
          <template #default>
            <el-button
              type="danger"
              size="mini"
            >
              {{ "开锁" }}
            </el-button>
            <el-button
              type="danger"
              size="mini"
            >
              {{ "授权" }}
            </el-button>
            <el-button
              type="danger"
              size="mini"
            >
              {{ "密码卡片" }}
            </el-button>
            <el-button
              type="danger"
              size="mini"
            >
              {{ "更多" }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin:50px 0"
        total="1"
        :page-sizes="[10, 20, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"
      />
    </el-card>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'
export default defineComponent({
  setup() {
    const state = reactive({
      rolesList: [
        {
          gateway: '未知',
          address: '大门',
          time: '2022-8-16 14:50:23',
          electricity: '50%',
          housenumber: '901',
          eid: 'C3995A5E',
          state: '在线'
        }
      ]
    })
    return {
      ...toRefs(state)
    }
  }
})
</script>
<style scoped>
  .main {
    margin: 30px;
  }
  .button-group{
    display: flex;
  }
  .set-button{
    margin: 0 30px;
    display: flex;
  }
  .set-left{
    width: 300px;
  }
  .set-right{
    flex: 1;
  }
</style>
